.LoginPage {
  min-height: 100vh;
  background-color: #FFFFFF !important;
}

.LoginPage .ui.grid {
  margin: 0 !important;
  height: 100vh !important;
  padding: 0 !important;
}

.LoginPage .ui.grid .center-box {
  width: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100% !important;
  overflow: auto !important;
}

@media only screen and (max-width: 767px) {
  .LoginPage .ui.grid .center-box {
    width: 100% !important;
  }
}

.LoginPage .ui.grid .center-box .row {
  width: 30% !important;
}

@media only screen and (max-width: 767px) {
  .LoginPage .ui.grid .center-box .row {
    width: 100% !important;
  }
}

.LoginPage .ui.grid .center-box .row .logo {
  height: 75px;
  width: 75;
  resize-mode: contain;
  align-self: center;
}
