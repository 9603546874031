html, body {
  background: #f7f7f7;
}

.root {
  overflow-x: hidden;
}

.text.center {
  text-align: center !important;
}

.field-error {
  color: #881d1d;
}

.input.search input {
  border-radius: 25px !important;
}

.error.field small {
  color: #b05d5b;
}

.HomePage {
  overflow-x: hidden;
}

.HomePage .hero {
  padding: 30px;
}

@media (min-width: 640px) {
  .HomePage .hero {
    padding: 0px;
  }
}

.HomePage .left-box {
  font-size: 1.3rem;
  flex-direction: column;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.HomePage .right-box {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.question-section {
  padding: 30px;
  font-size: 1rem;
  line-height: 30px;
  background-color: #a9e4f9;
}

.testimonial-section {
  font-size: 1rem !important;
  padding: 30px;
}

#footer #footer-shape {
  background-image: url(../assets/images/app/footer_wave.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar-top-fixed {
  padding: 0.5rem 1.5rem 0.5rem 2rem !important;
  background-color: #f7fafd !important;
  box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1) !important;
}

.ui.secondary.menu.front-p .item.hidden {
  display: none !important;
}

.ui.secondary.menu.front-p .right.menu.hidden {
  display: none !important;
}

.ui.secondary.menu.front-p .desktop {
  display: none !important;
}

@media (min-width: 1024px) {
  .ui.secondary.menu.front-p .desktop {
    display: flex !important;
  }
}

.ui.secondary.menu.front-p .mobile {
  display: flex !important;
}

@media (min-width: 1024px) {
  .ui.secondary.menu.front-p .mobile {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .front-sidebar {
    display: none !important;
  }
}
